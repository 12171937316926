<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { type BreadcrumbItem, type BreadcrumbPath } from '@/types/breadcrumbs'

const props = defineProps<{
  items: BreadcrumbItem[]
}>()
const router = useRouter()

const filteredItems = computed(() => {
  return props.items.filter((item) => !item.isHiden)
})

function navigate(path: BreadcrumbPath) {
  if (typeof path === 'string') {
    router.push({ name: path })
  } else if (typeof path === 'object') {
    router.push(path)
  }
}
</script>

<template>
  <div>
    <ul class="breadcrumbs">
      <li v-for="(item, n) in filteredItems" :key="n">
        <a @click="navigate(item.path)">
          {{ item.label || $t(`routes.${item.name}`) }}
        </a>
      </li>
    </ul>
  </div>
</template>
