<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

const props = defineProps<{ icon?: string }>()
const iconComponent = computed(() => {
  return defineAsyncComponent(() => import(`@/assets/icons/${props.icon}.svg?component`))
})
</script>

<template>
  <component :is="iconComponent" class="svg-icon" />
</template>
