import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { ErrorMessage } from '@/types/api'

export const useGlobalStore = defineStore(
  'global',
  () => {
    const globalErrors = ref<ErrorMessage[]>([])

    async function registerError(message: string) {
      globalErrors.value.push({ id: window.crypto.randomUUID(), message })
    }

    async function clearError(id: string) {
      globalErrors.value = globalErrors.value.filter(error => error.id !== id)
    }

    return {
      globalErrors,
      registerError,
      clearError
    }
  }
)
