import { type UserSession } from '@/api'
import { type MetaData } from '@/types/api'

export function getUserSession(key: string): UserSession | undefined {
  const localStorageSession = localStorage.getItem(key)
  if (localStorageSession) {
    const user: UserSession = JSON.parse(localStorageSession)
    return user
  }
}

export function isObject(obj: unknown) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
}

export function cloneObject<T>(object: T): T {
  return JSON.parse(JSON.stringify(object))
}

export function removeMetaData(object: MetaData): {} {
  const objectClone = cloneObject<MetaData>(object)
  delete objectClone.created_date
  delete objectClone.updated_date
  return objectClone
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function removeEmpty(obj: { [k: string]: unknown }) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

export function numberWithCommas(num: number): string {
  return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}