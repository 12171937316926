import { ref, computed, type Ref } from 'vue'

declare global {
  interface Window {
    topLayer: number
  }
}

export type BaseDropdownProps = {
  disabled?: boolean
  position?: 'is-left' | 'is-right'
  fitWidth?: boolean
  hideOnScroll?: boolean
  topOffset?: number
}

export type BaseDropdownEvents = {
  (event: 'open'): void
  (event: 'close'): void
}

export function useDropdownBase(
  props: BaseDropdownProps,
  emit: BaseDropdownEvents,
  dropdownEl: Ref<HTMLElement | null>
) {
  const isDropdownActive = ref(false)
  const zIndex = ref(0)
  const menuBounding = ref<DOMRect | null>(null)

  function toggle() {
    if (props.disabled) return false
    isDropdownActive.value = !isDropdownActive.value

    if (isDropdownActive.value) {
      window.topLayer = window.topLayer ? window.topLayer + 1 : 1
      zIndex.value = window.topLayer
      getBounding()
      window.addEventListener('scroll', onScroll, true)
      emit('open')
    } else {
      window.removeEventListener('scroll', onScroll, true)
      emit('close')
    }
  }

  function onScroll(e: Event) {
    if (props.hideOnScroll && e.target === document) {
      away()
    } else if (props.hideOnScroll && !(e.target as HTMLElement).classList.contains('dropdown')) {
      away()
    } else {
      getBounding()
    }
  }

  function getBounding() {
    const boundings = dropdownEl.value?.getBoundingClientRect()
    if (boundings) menuBounding.value = boundings
  }

  function away() {
    if (isDropdownActive.value) {
      isDropdownActive.value = false
      emit('close')
    }
  }

  const position = computed(() => {
    return menuBounding.value
      ? {
          top: menuBounding.value.top + menuBounding.value.height + (props.topOffset || 0),
          left: props.position === 'is-left' ? menuBounding.value.left : menuBounding.value.right,
          width: props.fitWidth ? menuBounding.value.width : -1,
          zIndex: zIndex.value
        }
      : { top: 0, left: 0, width: -1, zIndex: zIndex.value }
  })

  return { isDropdownActive, position, toggle, away }
}
