<script setup lang="ts">
import {
  useDropdownBase,
  type BaseDropdownProps,
  type BaseDropdownEvents
} from '@/composables/dropdownBase'
import { ref, computed } from 'vue'

declare global {
  interface Window {
    topLayer: number
  }
}

const props = withDefaults(
  defineProps<
    BaseDropdownProps & {
      triggerClass?: string | string[]
      contentClass?: string | string[]
    }
  >(),
  {
    position: 'is-left',
    topOffset: 0,
    triggerClass: 'dropdown-trigger',
    contentClass: 'dropdow-content'
  }
)

const emit = defineEmits<BaseDropdownEvents>()

const dropdownEl = ref<HTMLElement | null>(null)

const { isDropdownActive, position, toggle, away } = useDropdownBase(props, emit, dropdownEl)

const computedContentClass = computed(() => {
  return Array.isArray(props.contentClass)
    ? [...props.contentClass, props.position]
    : [props.contentClass, props.position]
})

defineExpose({ away })
</script>

<template>
  <div
    class="dropdown-wrapper"
    :class="{ 'is-active': isDropdownActive }"
    ref="dropdownEl"
    v-click-away="away"
  >
    <div :class="triggerClass" aria-haspopup="true" @click="toggle">
      <slot name="trigger"></slot>
    </div>
    <div
      :class="computedContentClass"
      :style="{
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: position.width ? `${position.width}px` : 'min-content',
        zIndex: position.zIndex + 999
      }"
      v-if="isDropdownActive"
    >
      <slot></slot>
    </div>
  </div>
</template>
