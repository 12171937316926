<script setup lang="ts">
import { ref, reactive } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRoute, useRouter } from 'vue-router'
import { setLanguage } from '@/lang'
import type { UserCredentials } from '@/types/users'

const credentials = reactive<UserCredentials>({ username: '', password: '' })

const loading = ref(false)

const user = useUserStore()
const router = useRouter()
const route = useRoute()

async function onSubmit() {
  loading.value = true
  const res = await user.login(credentials)

  if (res) {
    setLanguage(res.data.language)
    router.push('home')
  }
}
</script>

<template>
  <div class="login-layout">
    <div class="form">
      <el-alert
        v-if="route.query.unauthorized === 'yes'"
        class="l-mb-12"
        :title="$t('unauthorized_error')"
        type="error"
        effect="dark"
      />
      <el-form :model="credentials" label-position="top" size="large">
        <el-form-item label="Phone Number">
          <el-input v-model="credentials.username" placeholder="Enter phonenumber" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input
            v-model="credentials.password"
            type="password"
            placeholder="Enter password"
            show-password
          />
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Login</el-button>
      </el-form>
    </div>
  </div>
</template>
