export enum UserRole {
  GUEST = 'GUEST',
  ADMIN = 'ADMIN',
  DEALER = 'DEALER',
  CUSTOMER = 'CUSTOMER',
  MANAGER = 'MANAGER'
}

export enum OrderStatuses {
  NEW = 'NEW',
  PAYMENT_WAITING = 'PAYMENT_WAITING',
  PAID = 'PAID',
  DELIVERED = 'DELIVERED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED'
}

export enum OrderStatusTypes {
  NEW = 'primary',
  PAYMENT_WAITING = 'warning',
  PAID = 'success',
  DELIVERED = 'success',
  REJECTED = 'danger',
  CANCELED = 'danger'
}

export enum PaymentTypes {
  CASH = 'CASH',
  CARD = 'CARD',
  CREDIT = 'CREDIT'
}

export enum OrderCategories {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  PAID = 'PAID',
  DONE = 'DONE'
}

export enum OrderCategoriesTypes {
  ALL = 'primary',
  ACTIVE = 'danger',
  PAID = 'warning',
  DONE = 'success'
}