import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import routeGuard from './routeGuard'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginView
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('../views/CategoryList.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/category-create',
      name: 'categoryCreate',
      component: () => import('../views/CategoryCreate.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/category-edit/:id',
      name: 'categoryEdit',
      component: () => import('../views/CategoryEdit.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/colors',
      name: 'colors',
      component: () => import('../views/ColorList.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/color-create',
      name: 'colorCreate',
      component: () => import('../views/ColorCreate.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/color-edit/:id',
      name: 'colorEdit',
      component: () => import('../views/ColorEdit.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('../views/UserList.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/user-create',
      name: 'userCreate',
      component: () => import('../views/UserCreate.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/user-edit/:id',
      name: 'userEdit',
      component: () => import('../views/UserEdit.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('../views/ProductList.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/product-create',
      name: 'productCreate',
      component: () => import('../views/ProductCreate.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/product-edit/:id',
      name: 'productEdit',
      component: () => import('../views/ProductEdit.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/dealers',
      name: 'dealers',
      component: () => import('../views/DealerList.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/dealer-create',
      name: 'dealerCreate',
      component: () => import('../views/DealerCreate.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/dealer-edit/:id',
      name: 'dealerEdit',
      component: () => import('../views/DealerEdit.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/stock',
      name: 'stock',
      component: () => import('../views/StockList.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/stock-create',
      name: 'stockCreate',
      component: () => import('../views/StockCreate.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/banners',
      name: 'banners',
      component: () => import('../views/BannerList.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/banner-create',
      name: 'bannerCreate',
      component: () => import('../views/BannerCreate.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/banner-edit/:id',
      name: 'bannerEdit',
      component: () => import('../views/BannerEdit.vue'),
      beforeEnter: routeGuard()
    },
    {
      path: '/dealers-report',
      name: 'dealersReport',
      component: () => import('../views/DealersReport.vue'),
      beforeEnter: routeGuard()
    },
  ]
})

export default router
