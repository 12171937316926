import type { NavigationGuard } from 'vue-router'
import { useUserStore } from '@/stores/user'

const routeGuard =
  (role: string | null = null): NavigationGuard =>
  (to, _from, next) => {
    const user = useUserStore()
    if (user.isGuest) {
      next({ name: 'login', query: { next: to.fullPath } })
    } else if (role && !user[`is${role}` as keyof typeof user]) {
      next({ name: 'home' })
    } else {
      next()
    }
  }

export default routeGuard
