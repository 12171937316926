import axios, { type AxiosError, type AxiosResponse } from 'axios'
import router from '@/router'
import { getUserSession } from '@/utils'
import { useGlobalStore } from '@/stores/global'

const USER_SESSION = import.meta.env.VITE_SESSION_KEY

export type UserSession = {
  token: string
  language: string
}

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT
})

hydrate()

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.code < 0) {
      const globalStore = useGlobalStore()
      switch (response.data.code) {
        case -1000:
          destroyUserSession()
          router.push({ name: 'login', query: { unauthorized: 'yes' } })
          break
        default:
          globalStore.registerError(response.data.message)
          break
      }
    }
    return response
  },
  (error: AxiosError) => {
    // if (error.response?.data && typeof error.response?.data === 'object') {
    //   if (!('detail' in error.response.data)) return
    //   const globalStore = useGlobalStore()
    //   globalStore.registerError(String(error.response.data?.detail))
    // }
    const globalStore = useGlobalStore()
    if (error.message) globalStore.registerError(error.message)
    return Promise.resolve(error)
  }
)

export function setApiHeader(key: string, value: string) {
  api.defaults.headers.common[key] = value
}

export function removeApiHeader(key: string) {
  delete api.defaults.headers.common[key]
}

export function createUserSession(token: string, language: string) {
  setApiHeader('Authorization', `Bearer ${token}`)
  setApiHeader('Accept-Language', language)
  localStorage.setItem(USER_SESSION, JSON.stringify({ token, language }))
}

export function updateSessionLanguage(language: string) {
  const session = getUserSession(USER_SESSION)
  if (!session) return
  localStorage.setItem(USER_SESSION, JSON.stringify({ ...session, language }))
}

export function destroyUserSession() {
  removeApiHeader('Authorization')
  removeApiHeader('Accept-Language')
  localStorage.removeItem(USER_SESSION)
}

function hydrate() {
  const session = getUserSession(USER_SESSION)
  if (!session) return
  createUserSession(session.token, session.language)
}
