<script setup lang="ts">
// import UserNotifications from './UserNotifications.vue'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import UserMenu from './UserMenu.vue'
import { useUserStore } from '@/stores/user'
import { useI18n } from 'vue-i18n'

const user = useUserStore()
const { data } = storeToRefs(user)
const i18n = useI18n()

const language = ref<string>(data.value?.language || 'uz')
watch(language, () => {
  i18n.locale.value = language.value
  user.setUserLnaguage(language.value)
})
</script>

<template>
  <div class="layout-header">
    <div class="header-left">
      <div class="logo">
        <a>
          <img src="@/assets/logo.svg?url" alt="Project Logo" />
        </a>
      </div>
    </div>
    <div class="header-right">
      <div class="language-select">
        <el-select size="small" v-model="language" style="width: 100px">
          <el-option label="O`zbek" value="uz" />
          <el-option label="Русский" value="ru" />
        </el-select>
      </div>
      <div class="user-role">{{ user.data!.user_type }}</div>
      <!-- <UserNotifications /> -->
      <UserMenu />
    </div>
  </div>
</template>
