<script setup lang="ts">
import { ref, type Ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import DropdownMenu from '../DropdownMenu.vue'

const user = useUserStore()
const router = useRouter()
const dropdownComponent: Ref<InstanceType<typeof DropdownMenu> | null> = ref(null)

function settings() {
  dropdownComponent.value?.away()
  router.push('settings')
}

async function logout() {
  dropdownComponent.value?.away()
  await user.logout()
  router.push('/')
}
</script>

<template>
  <DropdownMenu position="is-right" :top-offset="4" ref="dropdown">
    <template #trigger>
      <div class="user nav-item">
        <img src="@/assets/avatar.png" class="avatar" />
        <SvgIcon icon="chevron-d" class="chevron" />
      </div>
    </template>
    <div class="user-menu">
      <div class="info">
        <span class="name">{{ user.data!.full_name }}</span>
      </div>
      <a @click="settings" v-if="false">{{ $t('settings') }}</a>
      <a @click="logout">{{ $t('logout') }}</a>
    </div>
  </DropdownMenu>
</template>
