<script setup lang="ts">
import { useGlobalStore } from '@/stores/global';
import { inject, computed } from 'vue'
import { useI18n } from 'vue-i18n';

type FileValue = string | File[]

const props = defineProps<{
  modelValue?: FileValue
  multiple?: boolean
  image?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [value: FileValue]
  change: []
}>()

const currentFileName = computed<string | void>(() => {
  if (typeof props.modelValue !== 'string') return
  return props.modelValue.split('/').pop()
})

const remoteStatic = inject('remoteStatic')
const globalStore = useGlobalStore()
const { t } = useI18n()

function onChange(e: Event) {
  const files = (e.target as HTMLInputElement).files
  if (!files || files.length === 0) return
  for(const file of files) {
    if((file.size / 1000000) > 10) {
      globalStore.registerError(t('filesize_error'))
      return
    }
  }
  emit('update:modelValue', [...files])
}

function removeFile(filename: string) {
  if (!Array.isArray(props.modelValue)) return
  const files = props.modelValue.filter((file) => file.name !== filename)
  emit('update:modelValue', [...files])
}

function filePreview(file: File): string {
  return URL.createObjectURL(file)
}
</script>

<template>
  <div class="file-input">
    <div class="trigger">
      <input type="file" @change="onChange" :multiple="multiple" />
      <el-button type="primary">{{ $t('select_file') }}</el-button>
    </div>
    <div class="files-list">
      <template v-if="typeof modelValue === 'string'">
        <a :href="`${remoteStatic}${modelValue}`" target="_blank">
          <div v-if="image" class="input-thumb">
            <img :src="`${remoteStatic}${modelValue}`" />
          </div>
          <span v-else>{{ currentFileName }}</span>
        </a>
      </template>
      <template v-else>
        <div v-for="file in modelValue" :key="file.name">
          <div v-if="image" class="input-thumb">
            <img :src="filePreview(file)" />
          </div>
          <template v-else>
            <span>{{ file.name }}</span>
            <button @click="removeFile(file.name)"><svg-icon icon="close" /></button>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>
