import { type App } from 'vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FileInput from '@/components/FileInput.vue'
import BaseLayout from '@/components/layout/BaseLayout.vue'
import LocationPicker from './components/LocationPicker.vue'
import { createYmaps } from 'vue-yandex-maps'

export default {
  install(app: App) {
    // y-maps
    app.use(createYmaps({ apikey: import.meta.env.VITE_YMAPS_API_KEY }))
    // components
    app.component('DropdownMenu', DropdownMenu)
    app.component('SvgIcon', SvgIcon)
    app.component('FileInput', FileInput)
    app.component('BaseLayout', BaseLayout)
    app.component('LocationPicker', LocationPicker)
    // variables
    window.remoteStatic = import.meta.env.VITE_APP_STATIC_URL
    app.provide('remoteStatic', import.meta.env.VITE_APP_STATIC_URL)
  }
}
