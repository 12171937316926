import { createI18n } from 'vue-i18n'
import uz from './translations/uz.yaml'
import ru from './translations/ru.yaml'
import { getUserSession } from '@/utils'

export const availableLanguages = { uz, ru }
export type Language = keyof typeof availableLanguages
const sessionLanguage = getUserSession(import.meta.env.VITE_SESSION_KEY)?.language

export const i18n = createI18n({
  legacy: false,
  locale: sessionLanguage || 'uz',
  fallbackLocale: 'uz',
  messages: availableLanguages
})

export function setLanguage(language: Language): boolean {
  if (Object.keys(availableLanguages).includes(language) === false) {
    console.warn(`"${language}" is not an available language.`)
    return false
  } else {
    i18n.global.locale.value = language;
    (document.querySelector('html') as HTMLElement).setAttribute('lang', language)
  }

  return true
}
