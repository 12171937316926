import { createApp } from 'vue'
import { i18n } from './lang'
import store from './stores'
import router from './router'
import clickAway from 'vue3-click-away'
import globalComponents from './globalComponents'
import ElementPlus from 'element-plus'
import App from './App.vue'
import * as Sentry from "@sentry/vue"

import 'element-plus/dist/index.css'
import './scss/main.scss'

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://c308c6b24a5f78190bb0820818048bbf@o1161019.ingest.us.sentry.io/4507821397770240",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(store)
app.use(router)
app.use(i18n)
app.use(clickAway)
app.use(ElementPlus)
app.use(globalComponents)

app.mount('#app')
