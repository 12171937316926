<script setup lang="ts">
import { shallowRef } from 'vue'
import type { YMap, LngLat, DomEventHandler } from '@yandex/ymaps3-types'
import {
  YandexMap,
  YandexMapDefaultSchemeLayer,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultMarker,
  YandexMapListener,
  YandexMapControls,
  YandexMapZoomControl,
  YandexMapGeolocationControl
} from 'vue-yandex-maps'
import type { Coordinates } from '@/types/helpers'

const props = defineProps<{ modelValue?: Coordinates | null }>()
const emit = defineEmits<{
  'update:modelValue': [value: Coordinates]
}>()

const map = shallowRef<null | YMap>(null)
const coordinates = shallowRef<LngLat | null>(props.modelValue || null)

const onClick: DomEventHandler = (_, event) => {
  coordinates.value = event.coordinates
  emit('update:modelValue', event.coordinates)
}
</script>

<template>
  <div>
    <yandex-map
      v-model="map"
      :settings="{
        location: {
          center: coordinates || [69.265, 41.305],
          zoom: 12
        }
      }"
      width="680px"
      height="450px"
    >
      <yandex-map-default-scheme-layer />
      <yandex-map-controls :settings="{ position: 'right' }">
        <yandex-map-zoom-control />
        <yandex-map-geolocation-control />
      </yandex-map-controls>
      <yandex-map-default-features-layer />
      <yandex-map-listener :settings="{ onClick }" />
      <yandex-map-default-marker v-if="coordinates" :settings="{ coordinates }" />
    </yandex-map>
  </div>
</template>
