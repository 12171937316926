<script setup lang="ts">
import { useUserStore } from '@/stores/user'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const user = useUserStore()
const router = useRouter()
const { t } = useI18n()

async function logout() {
  await user.logout()
  router.push('/')
}

type MenuItem = {
  name: string
  link: string
  icon: string
  show: boolean
}

const menuItems = computed<MenuItem[]>(() => [
  { name: t('dashboard'), link: '/home', icon: 'dashboard', show: true },
  {
    name: t('report'),
    link: '/dealers-report',
    icon: 'list',
    show: user.isAdmin || user.isManager
  },
  { name: t('products'), link: '/products', icon: 'products', show: user.isAdmin },
  { name: t('categories'), link: '/categories', icon: 'categories', show: user.isAdmin },
  { name: t('colors'), link: '/colors', icon: 'colors', show: user.isAdmin },
  { name: t('stock'), link: '/stock', icon: 'stock', show: user.isAdmin || user.isManager },
  { name: t('banners'), link: '/banners', icon: 'banner', show: user.isAdmin },
  { name: t('dealers'), link: '/dealers', icon: 'dealers', show: user.isAdmin },
  { name: t('users'), link: '/users', icon: 'users', show: user.isAdmin }
])
</script>

<template>
  <div class="left-sidebar">
    <div class="section-top">
      <ul>
        <li v-for="menu in menuItems" :key="menu.name" v-show="menu.show">
          <RouterLink :to="menu.link" class="menu-item">
            <div class="wrapper">
              <span class="icon">
                <SvgIcon :icon="menu.icon" />
              </span>
              <span class="name">{{ menu.name }}</span>
            </div>
            <span class="badge" v-if="false">12</span>
          </RouterLink>
        </li>
      </ul>
    </div>
    <div class="section-bottom">
      <ul>
        <li>
          <a class="menu-item logout" @click="logout">
            <div class="wrapper">
              <span class="icon">
                <SvgIcon icon="exit" />
              </span>
              <span class="name">{{ $t('logout') }}</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
