<script setup lang="ts">
import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '@/stores/global'
import { ElMessage } from 'element-plus'

const globalStore = useGlobalStore()
const { globalErrors } = storeToRefs(globalStore)
watch(
  () => globalErrors,
  () => {
    if (globalErrors.value.length === 0) return
    for (const error of globalErrors.value) {
      ElMessage.error(error.message)
      globalStore.clearError(error.id)
    }
  },
  { deep: true }
)
</script>

<template><div /></template>
